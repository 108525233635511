import { ApiClient, SphereDashboardAPITypes } from "@stellar/api-logic";
import { IntegrationCompany, IntegrationProject } from "@services/integrations-service/integrations-service-types";
import { ProcoreEntity } from "@services/integrations-service/procore/procore-types";

interface Props {
  /** Core API client instance */
  coreApiClient: ApiClient;
  /** URL of the Procore API */
  procoreApiUrl: string;
}

/** Service that provides methods to manage the Procore 3rd party integration */
export class ProcoreService {
  #coreApiClient: ApiClient;
  #procoreApiUrl: string;

  constructor({
    coreApiClient,
    procoreApiUrl,
  }: Props) {
    this.#coreApiClient = coreApiClient;
    this.#procoreApiUrl = procoreApiUrl;
  }

  /**
   * @returns the IntegrationCompany entities of the user
   * @throws {Error} if it fails to get the entities
   */
  public async getIntegrationCompanies(): Promise<IntegrationCompany[]> {
    const procoreCompanies = await this.getProcoreCompanies();

    return procoreCompanies.map((procoreCompany) => ({
      integrationId: SphereDashboardAPITypes.IntegrationId.procore,
      id: procoreCompany.id.toString(),
      name: procoreCompany.name,
    }));
  }

  /**
   * @returns the IntegrationProject entities for a given integration company
   * @throws {Error} if it fails to get the entities
   * @param companyId ID of the company
   */
  public async getIntegrationProjects(
    companyId: string
  ): Promise<IntegrationProject[]> {
    const procoreProjects = await this.getProcoreProjects(companyId);

    const integrationProjects: IntegrationProject[] = procoreProjects.map((procoreProject) => {
      const bcfProjectId = `${procoreProject.id}~${companyId}`;
      return {
        integrationId: SphereDashboardAPITypes.IntegrationId.procore,
        id: procoreProject.id.toString(),
        name: procoreProject.name,
        companyId,
        rfisBcfProjectId: bcfProjectId,
        issuesOrObservationsBcfProjectId: bcfProjectId,
        type: "none",
      };
    });

    return integrationProjects;
  }

  /**
   * @returns the companies of the user
   * @throws {Error} if it fails to fetch the companies
   */
  private async getProcoreCompanies(): Promise<ProcoreEntity[]> {
    return this.request({
      url: "companies",
      verb: "GET",
    });
  }

  /**
   * @returns the projects for a given company
   * @throws {Error} if it fails to fetch the projects
   * @param companyId ID of the company
   */
  private async getProcoreProjects(companyId: string): Promise<ProcoreEntity[]> {
    return this.request({
      url: `companies/${companyId}/projects`,
      verb: "GET",
    });
  }

  /**
   * Issues a request to the Procore REST V1.0 API
   * @returns the response of the issued request
   * @throws {Error} if the request fails
   * @param url of the endpoint to issue the request
   * @param verb the request method
   * @param headers optional request headers to add
   */
  private async request<T>({
    url,
    verb,
    headers,
  }: SphereDashboardAPITypes.ProxyRequestPayload): Promise<T> {
    return this.#coreApiClient.V2.SDB.proxyRequest<T>(
      SphereDashboardAPITypes.IntegrationId.procore,
      {
        url: `${this.#procoreApiUrl}/rest/v1.0/${url}`,
        verb,
        headers,
      }
    );
  }
}
